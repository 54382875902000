<template>
  <div>
    <CForm @submit="submitFilterValues">
      <CCard>
        <CButton
          @click="cardCollapse = !cardCollapse"
          tag="button"
          color="link"
          block
          class="flex flex--space-between text-left shadow-none card-header"
        >
          <h5 class="m-0">Filtros</h5>
          <CIcon :name="`cil-chevron-${cardCollapse ? 'top' : 'bottom'}`" />
        </CButton>
        <CCollapse :show="cardCollapse">
          <CCardBody>
            <CRow>
              <CCol sm="12">
                <label class="filter-label">{{ messages.filters.area }}</label>
                <FiltersAreaCombo :activeElements="activeItems" :allCountries="true" :pre-selected="preSelected"/>
                <span
                  v-if="areaError"
                  class="error-message error-message--bottom"
                  >{{ messages.validation.filterAreaCombo }}</span
                >
              </CCol>
            </CRow>
            <CRow>
              <CCol xl="3">
                <label class="filter-label">FY</label>
                <FilterFY />
              </CCol>
              <CCol xl="6">
                <label class="filter-label">{{
                  messages.filters.period
                }}</label>
                <FilterMonthsRange />
              </CCol>
              <CCol xl="3">
                <label class="filter-label">{{
                  messages.filters.cumulativeOrNot
                }}</label>
                <FilterMonthlyDetail />
              </CCol>
            </CRow>
          </CCardBody>
          <CCardFooter>
            <CButton type="submit" color="primary">Mostrar</CButton>
          </CCardFooter>
        </CCollapse>
      </CCard>
    </CForm>
    <CRow v-if="showLoader" class="justify-content-center">
      <Loader
        width="100px"
        height="100px"
        fill-color="#c2c2c2"
        cssClass="fadeIn anim-speed--1s"
      />
    </CRow>
    <CRow v-if="showTable" class="fadeIn anim-speed--2s">
      <CCol sm="12">
        <table-purchase-data-detail :data="tableRawData">
          <span slot="tableTitle">{{ selectedArea }} {{ selectedArea && '-' }}
            {{ selectedSinceMonth === selectedUpToMonth ? selectedSinceMonth : ` de ${selectedSinceMonth} a ${selectedUpToMonth}`}}
             / {{ selectedFy }}</span>
        </table-purchase-data-detail>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import Api from "@/services/Api";
import currentFY from "@/services/CurrentFY";
import langStr from "@/utils/langStr";
import defaultAreaParam from "@/helpers/defaultAreaParam";
import calculatePeriod from "@/helpers/calculatePeriod";
import TablePurchaseDataDetail from "@/components/organisms/TablePurchaseDataDetail";
import formatFiscalYear from "@/helpers/formatFiscalYear";
import getAreaName from "@/helpers/getAreaName";

const FiltersAreaCombo = () =>
  import(
    /* webpackChunkName: "filtersAreaCombo" */ "@/components/organisms/FiltersAreaCombo"
  );
const FilterFY = () =>
  import(
    /* webpackChunkName: "filter-fiscal-year" */ "@/components/molecules/FilterFY"
  );
const FilterMonthsRange = () =>
  import(
    /* webpackChunkName: "filterMonthsRange" */ "@/components/molecules/FilterMonthsRange"
  );

const FilterMonthlyDetail = () =>
  import(
    /* webpackChunkName: "filterMonthsRange" */ "@/components/molecules/FilterMonthlyDetail"
  );

const Loader = () => import("@/components/atoms/Loader");

export default {
  name: "PurchaseDataDetail",
  components: {
    TablePurchaseDataDetail,
    FiltersAreaCombo,
    FilterFY,
    FilterMonthsRange,
    FilterMonthlyDetail,
    Loader,
  },
  data() {
    return {
      filtersList: null,
      cardCollapse: true,
      activeItems: ["countries", "regionals", "aspms", "concessions"],
      areaError: false,
      tableRawData: null,
      showLoader: true,
      showTable: false,
      preSelected: null,
      selectedArea: null,
      selectedFy: null,
      selectedSinceMonth: null,
      selectedUpToMonth: null,
    };
  },
  computed: {
    lang() {
      return this.$store.getters.getCurrentLang;
    },
    messages() {
      return this.$store.getters.getLangMessages;
    },
    user() {
      return this.$store.getters.getCurrentUser;
    },
  },
  methods: {
    defaultQuery() {
      currentFY().then((fiscalYear) => {
        const defaultArea = defaultAreaParam(this.user);
        const defaults = {};
        const areaKey = defaultArea.key;

        defaults[areaKey] = defaultArea.value;
        defaults.fiscalYear = fiscalYear;

        defaults.sinceMonth = new Date().getMonth() + 1;
        defaults.upToMonth = new Date().getMonth() + 1;
        defaults.isCumulative = 1;
        defaults.languageId = langStr.getId(this.lang);
        this.makeApiQuery(defaults);
      });
    },
    submitFilterValues(event) {
      event.preventDefault();
      let validated;
      if (!this.$store.getters.getFiltersAreaComboParams) {
        // Show area filter validation error
        this.areaError = true;
      } else {
        // Hide area filter validation error
        this.areaError = false;
        validated = true;
      }

      if (validated) {
        const params = {
          ...this.$store.getters.getFiltersAreaComboParams,
          fiscalYear: this.$store.getters.getFilterFYValue,
          sinceMonth: this.$store.getters.getFilterMonthSinceValue,
          upToMonth: this.$store.getters.getFilterMonthUpToValue,
          isCumulative: this.$store.getters.getFilterIsCumulativeValue,
          languageId: langStr.getId(this.lang),
        };
        this.makeApiQuery(params);
      }
    },
    makeApiQuery(params) {
      // Uptate table title
      this.updateTableTitle(params);
      // Show the loader and hide the table until data is received
      this.showLoader = true;
      this.showTable = false;
      Api.sendPost("commercial/purchasesDetail", params).then((response) => {
        if (response.status === 200) {
          if (response.data.code === 200) {
            this.showLoader = false;
            this.showTable = true;
            // Set the processed data variable to send to the DataTable component
            this.tableRawData = response.data.data;
          }
        }
      });
    },
    updateTableTitle(params) {
      this.selectedArea = getAreaName(params, this.messages);
      this.selectedFy = formatFiscalYear(params.fiscalYear);
      this.selectedSinceMonth = this.messages.filters.months[params.sinceMonth];
      this.selectedUpToMonth = this.messages.filters.months[params.upToMonth];
    }
  },
  mounted() {
    this.defaultQuery();
  },
};
</script>

<style scoped></style>
