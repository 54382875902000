var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CCard',[_c('CCardHeader',{staticClass:"flex flex--space-between flex--align-items-baseline"},[_c('span',{staticClass:"col-xs-12 col-md-9 mb-2 mb-md-0 p-0"},[_c('strong',[_vm._v(_vm._s(_vm.messages.tables.results)+" ")]),_vm._t("tableTitle")],2),_c('CButton',{staticClass:"gtm-tag-export",attrs:{"color":"light"},on:{"click":_vm.exportHTMLToExcel}},[_vm._v(" "+_vm._s(_vm.messages.tables.export.toUpperCase())+" "),_c('LogoExcel')],1)],1),_c('CCardBody',[_c('CDataTable',{ref:_vm.tableId,attrs:{"hover":"","outlined":"","border":"","clickableRows":"","id":"excelArea","fields":_vm.headCells,"items":_vm.bodyRows,"columnFilter":false,"tableFilter":{
        label: _vm.messages.tables.filter,
        placeholder: `${_vm.messages.tables.search} ...`,
      },"cleaner":"","noItemsView":{
        noItems: _vm.messages.tables.noItems,
        noResults: _vm.messages.tables.noResults,
      }},scopedSlots:_vm._u([{key:"thead-top",fn:function(){return [_c('tr',{staticClass:"thead-top"},[_c('th'),_c('th'),_vm._l((_vm.theadCells),function(cell,index){return _c('th',{key:index,attrs:{"colspan":cell.colspan}},[_vm._v(" "+_vm._s(cell.description)+" ")])})],2)]},proxy:true}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }