<template>
  <CCard>
    <CCardHeader class="flex flex--space-between flex--align-items-baseline">
      <span class="col-xs-12 col-md-9 mb-2 mb-md-0 p-0"><strong>{{ messages.tables.results }} </strong><slot name="tableTitle"></slot></span>
      <CButton color="light" class="gtm-tag-export" @click="exportHTMLToExcel">
        {{ messages.tables.export.toUpperCase() }}
        <LogoExcel />
      </CButton>
    </CCardHeader>
    <CCardBody>
      <CDataTable
        hover
        outlined
        border
        clickableRows
        id="excelArea"
        :ref="tableId"
        :fields="headCells"
        :items="bodyRows"
        :columnFilter="false"
        :tableFilter="{
          label: messages.tables.filter,
          placeholder: `${messages.tables.search} ...`,
        }"
        cleaner
        :noItemsView="{
          noItems: messages.tables.noItems,
          noResults: messages.tables.noResults,
        }"
      >
        <template #thead-top>
          <tr class="thead-top">
            <th></th>
            <th></th>
            <th
              v-for="(cell, index) in theadCells"
              :key="index"
              :colspan="cell.colspan"
            >
              {{ cell.description }}
            </th>
          </tr>
        </template>
      </CDataTable>
    </CCardBody>
  </CCard>
</template>

<script>
import formatNum from "@/utils/formatNum";
import LogoExcel from "@/components/atoms/LogoExcel";
import ExportHTML2Excel from "@/helpers/ExportHTML2Excel";

export default {
  name: "TablePurchaseDataDetail",
  components: {
    LogoExcel,
  },
  data() {
    return {
      tableId: "purchaseDetail",
      array: ['a', 'b', 'c'],
    };
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  computed: {
    messages() {
      return this.$store.getters.getLangMessages;
    },
    theadCells() {
      const cells = [];
      if (this.data.length) {
        const firstRow = this.data[0].monthlyDetails[0].productDetails;
        firstRow.map((elem) => {
          cells.push({
            description: elem.description,
            colspan: Object.keys(elem).length - 1,
          });
        });
      }
      return cells;
    },
    headCells() {
      const cells = [
        {
          key: "dealer",
          label: this.messages.purchaseDataDetail.dealer.toUpperCase(),
          _classes: "headCell dealer",
        },
        {
          key: "period",
          label: this.messages.purchaseDataDetail.period.toUpperCase(),
          _classes: "headCell",
        },
      ];
      const firstRow = this.data[0].monthlyDetails[0].productDetails;
      firstRow.map((product, i) => {
        Object.keys(product).map((key) => {
          if (key !== "description")
            cells.push({
              key: `${key}-${i}`,
              // label: `${key}-${i}`,
              label: this.messages.purchaseDataDetail[key],
              _classes: "headCell",
            });
        });
      });
      return cells;
    },
    bodyRows() {
      const rows = [];
      const rowsByConcession = [...this.data];
      rowsByConcession.map((concessionData) => {
        // console.log(concessionData.businessName, concessionData.monthlyDetails);
        const monthlyDetails = concessionData.monthlyDetails;
        monthlyDetails.map((monthData, i) => {
          const row = {
            dealer: `${concessionData.concessionCode} - ${concessionData.businessName}`,
            period:
              monthData.month !== 0
                ? this.messages.filters.months[monthData.month]
                : "",
          };
          const products = monthData.productDetails;
          products.map((product, i) => {
            for (let prop in product) {
              if (prop !== "description") {
                const key = `${prop}-${i}`;
                row[key] = `${formatNum.get(product[prop], 2)} €`;
              }
            }
          });
          rows.push(row);
        });
      });
      return rows;
    },
  },
  methods: {
    exportHTMLToExcel() {
      ExportHTML2Excel(
        document.querySelector("#excelArea table.table"),
        this.messages.purchaseDataDetail.title
      );
    },
  },
};
</script>

<style lang="scss">
thead {
  .thead-top {
    text-align: center;
    font-weight: bold;
    color: #ffffff;
    background: #666666;
    height: 32px;
    vertical-align: middle;
    border-left: 1px solid #dddddd;
  }
  .headCell {
    font-weight: bold;
    background: #dddddd;
    height: 32px;
    vertical-align: middle;
    text-align: center;

    &.dealer {
      text-align: left;
    }
  }
}
</style>
